import { Select } from 'antd';
<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" class="formInquire">
        <a-row :gutter="48" class="orderTop">
          <a-col :md="9" :sm="15">
            <a-form-item label="寄件地址">
              <a-select placeholder="请选择寄件地址"
                v-model="queryParam.shipperGeoCode">              
                <a-select-option v-for="(record, idx) in shipperGeoData" :key="idx" :value="idx">
                  {{ record.provinceName }} / {{ record.cityName }} / {{ record.districtName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="9" :sm="15">
            <a-form-item label="收件地址">
               <a-radio-group  name="receiverType"    
                v-model="queryParam.receiverType"                           
                  @change="receiverChange($event)"                 
                >
                  <a-radio  v-for="(item, index) in receiverTypeData" :key="item.enumValue" :value="item.enumValue">{{ item.enumName }}</a-radio>
                </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :md="9" :sm="15" v-if="provinceBool">
            <a-form-item label="省份">
              <a-select  name="provinceCode" mode="tags" :maxTagCount="3" v-model="queryParam.provinceCode"   placeholder="请选择省份">
                <a-select-option v-for="(record, idx) in provinceData" :key="record.value.toString()" :value="record.value.toString()">
                  {{ record.label}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="9" :sm="15" v-if="regionBool">
            <a-form-item label="大区">
              <a-select  name="regionCode" mode="tags" :maxTagCount="2"   v-model="queryParam.regionCode"    placeholder="请选择大区"
               >
                <a-select-option v-for="(record, idx) in regionData" :key="record.enumValue.toString()" :value="record.enumValue.toString()">
                  {{ record.enumName}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="9" :sm="15" v-if="customizeBool">
            <a-form-item label="收件地址">
              <a-select    v-model="queryParam.receiverGeoCode" mode="tags" :maxTagCount="1" placeholder="请选择收件地址">
                <a-select-option v-for="(record, idx) in receiverGeoData" :key="idx" :value="record.cityName.toString()">
                  {{ record.cityName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

        </a-row>
       
    
        <a-row :gutter="48">
          <a-col :md="9" :sm="15">
            <a-form-item label="选择重量" >
                <a-checkbox-group  v-model="queryParam.actualWeight">
                  <a-checkbox v-for="(item, index) in weightTypeData" :value="item.enumName" :key="item.enumName">
                    {{ item.enumName }}
                  </a-checkbox>
                </a-checkbox-group>
              </a-form-item>
          </a-col>

         <a-col :md="9" :sm="15">  

            <a-form-item label="备注">
              <a-textarea
                class="textarea"
                auto-size                
                v-model="content2"                 
              :maxLength="150" placeholder="150字以内" />
            </a-form-item>
          </a-col>
         
        </a-row>
        
        <a-row :gutter="48">
          <a-col>
              <span class="table-page-search-submitButtons">
                <a-button type="primary"  @click="$refs.table.refresh(true)">查询报价</a-button>
                <a-button style="margin-left: 10px" @click="() => (queryParam = {receiverType:1,actualWeight:[]},provinceBool=false,regionBool=false,customizeBool=false)">重置</a-button>
                <a-button type="primary" icon="download" @click="downloadExcel()" style="margin-left:15px">下载报价表</a-button>
              </span>
          </a-col>
        </a-row>
      </a-form>
    </div>   
    
    <s-table
      ref="table"
      size="default"
      rowKey="idx"
      :columns="columns"
      :data="loadData"
      :page-size="10"
      :scroll="{ x: 'max-content' }"
      class="tableOrder"
      bordered
    >
     
    </s-table>

  </div>
</template>
<script>
import { STable,Ellipsis } from '@/components'
import { pickupList, dispatchCityUniqueList, sectionPrice,extTextInfo, geoCascaderList,lowestCharge} from '@/api/quotation'
import { enumerationData } from '@/api/common'
import { getOrderManagerList } from '@/api/order'
import XLSX from 'xlsx'

const columns = [
  {
    key: 'shipperProvinceName',
    dataIndex: 'shipperProvinceName',
    title: '始发省份',
    align: 'center', 
    fixed:'left',
     width: 100 ,
    sortIndex:1,  
  },
  {
    key: 'shipperCityName',
    dataIndex: 'shipperCityName',
    title: '始发城市',
    align: 'center',
    fixed:'left',
     width: 100 ,
    sortIndex:2,
  }, 
  {
    key: 'receiverProvinceName',
    dataIndex: 'receiverProvinceName',
    title: '目的省份',
    align: 'center',
    fixed:'left',
     width: 100 ,
    sortIndex:3,
  },
  {
    key: 'receiverCityName',
    dataIndex: 'receiverCityName',
    title: '目的城市',
    align: 'center',
    fixed:'left',
    width: 100 ,
    sortIndex:4,
  },
  {
    key: 'firstWeight',
    dataIndex: 'firstWeight',
    title: '首重KG',
    align: 'center',
    sortIndex:5,
  },
  {
    key: 'lowestPrice',
    dataIndex: 'lowestPrice',
    title: '最低收费（元）',   
    align: 'center',
    sortIndex:6,
  },
  {
    key: 'pickupFirstWeightPrice',
    dataIndex: 'pickupFirstWeightPrice',
    title: '提货起步（元）',
    align: 'center',
    sortIndex:7,
  },
  {
    key: 'pickupContinuedWeightPrice',
    dataIndex: 'pickupContinuedWeightPrice',
    title: '提货费单价（元）',
    align: 'center',
    sortIndex:7,
  },
  {
    key: 'pickupUpperLimitPrice',
    dataIndex: 'pickupUpperLimitPrice',
    title: '提货费上限（元）',
    align: 'center',
    sortIndex:8,
  },
  {
    key: 'dispatchUpperLimitPrice',
    dataIndex: 'dispatchUpperLimitPrice',
    title: '派费上限（元）',
    align: 'center',
    sortIndex:9,
  }, 
]


const range2FirstWeightPrice = {   
    title: '100KG',
    sortIndex:10,  
    children: [
       {
            title: '干线单价（元）',
            dataIndex: 'range2FirstWeightPrice.transportPrice',          
            width: 150,    
            align: 'center',      
        },
        {
            title: '派送最低收费（元）',
            dataIndex: 'range2FirstWeightPrice.dispatchFirstWeightPrice',         
            width: 150,   
            align: 'center',       
        },
        {
            title: '派送续重单价（元）',
            dataIndex: 'range2FirstWeightPrice.dispatchContinuedWeightPrice',            
            width: 150,   
            align: 'center',        
        },
    ],
       
}

const range3FirstWeightPrice = {   
    title: '300KG',
    sortIndex:11,     
    children: [
       {
            title: '干线单价（元）',
            dataIndex: 'range3FirstWeightPrice.transportPrice',          
            width: 150,  
            align: 'center',        
        },
        {
            title: '派送最低收费（元）',
            dataIndex: 'range3FirstWeightPrice.dispatchFirstWeightPrice',         
            width: 150,   
            align: 'center',       
        },
        {
            title: '派送续重单价（元）',
            dataIndex: 'range3FirstWeightPrice.dispatchContinuedWeightPrice',           
            width: 150,   
            align: 'center',        
        },
    ],
    
  }

const range4FirstWeightPrice = {   
    title: '500KG',
     sortIndex:12,     
    children: [
      {
            title: '干线单价（元）',
            dataIndex: 'range4FirstWeightPrice.transportPrice',          
            width: 150, 
            align: 'center',         
        },
        {
            title: '派送最低收费（元）',
            dataIndex: 'range4FirstWeightPrice.dispatchFirstWeightPrice',         
            width: 150,     
            align: 'center',     
        },
        {
            title: '派送续重单价（元）',
            dataIndex: 'range4FirstWeightPrice.dispatchContinuedWeightPrice',           
            width: 150,  
            align: 'center',         
        },
    ],
     
  }

const  range5FirstWeightPrice = {   
    title: '1200KG',
     sortIndex:13,    
    children: [
       {
            title: '干线单价（元）',
            dataIndex: 'range5FirstWeightPrice.transportPrice',          
            width: 150,   
            align: 'center',       
        },
        {
            title: '派送最低收费（元）',
            dataIndex: 'range5FirstWeightPrice.dispatchFirstWeightPrice',         
            width: 150,    
            align: 'center',      
        },
        {
            title: '派送续重单价（元）',
            dataIndex: 'range5FirstWeightPrice.dispatchContinuedWeightPrice',           
            width: 150,       
            align: 'center',    
        },
    ],
      
  }  




export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    XLSX,
  },
  data() {
    return {        
      shipperGeoData: [],
      queryParam: {},
      content2:"",
      pageDataset: [],
      receiverGeoData: [],             
      weightTypeData:[],                   
      downloadExcelState: 0,
      downloadExcelDataset: [],
      provinceData:[],     
      receiverTypeData:[],
      provinceBool:false,
      customizeBool:false,
      regionBool:false,
      regionData:[],
      

      columns: columns,  
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {             
        this.getLoadData()              
        var returnResult = {
                      pageNo: 1,
                      pageSize: 5,
                      pageCount: 0,
                      totalCount: 0,
                      result: [],
                      startIndex: 0,
                      endIndex: 0,
                      first: 1,
                    }
       if(this.queryParam.shipperGeoCode == undefined){
             var emptyData = new Promise(function (resolve, reject) {                   
                    resolve(returnResult)
              })
            this.queryParam.receiverType = 1
            return emptyData
       }else{        
          var checkQueryParamBool = this.pageFormSubmit() 
          if(checkQueryParamBool == false){
              var emptyData = new Promise(function (resolve, reject) {                   
                    resolve(returnResult)
              })           
             return emptyData
          }
               
          return sectionPrice(Object.assign(parameter, this.queryParam))
            .then((res) => {
              if(0 == res.code) {
                if(this.queryParam.actualWeight.indexOf("100") > -1){
                        var showBool = this.checkFieldsShow('100KG')                        
                        if(showBool){                          
                            columns.push(range2FirstWeightPrice)
                        }                                           
                  }else{
                     var pos = this.locationPos('100KG')     
                     if(pos > -1){
                        columns.splice(pos,1)                       
                     }                        
                  }                   
                  if(this.queryParam.actualWeight.indexOf("300") > -1){
                     var showBool = this.checkFieldsShow('300KG')                        
                      if(showBool){
                              columns.push(range3FirstWeightPrice)
                      }                                             
                  }else{
                     var pos = this.locationPos('300KG')     
                     if(pos > -1){
                        columns.splice(pos,1)                       
                     }                           
                  }

                 if(this.queryParam.actualWeight.indexOf("500") > -1){
                     var showBool = this.checkFieldsShow('500KG')                        
                      if(showBool){
                              columns.push(range4FirstWeightPrice)
                      }                                             
                  }else{
                     var pos = this.locationPos('500KG')     
                     if(pos > -1){
                        columns.splice(pos,1)                       
                     }                           
                  }

                 if(this.queryParam.actualWeight.indexOf("1200") > -1){
                     var showBool = this.checkFieldsShow('1200KG')                        
                      if(showBool){
                              columns.push(range5FirstWeightPrice)
                      }                                             
                  }else{
                     var pos = this.locationPos('1200KG')     
                     if(pos > -1){
                         columns.splice(pos,1) 
                     }                        
                  }
                      
                columns.sort((p1,p2)=>{
                    return p1.sortIndex-p2.sortIndex // 升序排序
                  }) 
                let resultData = res.result.result                           
                for (let i = 0; i < resultData.length; i++) {
                  resultData[i].idx = i                                           
                }    
                res.result.result =  resultData                          
                return res.result;       
              } else {
                this.$notification.error({
                  message: '加载失败',
                  description: res.message,
                })                
                return  returnResult
              }              
            })
            .catch((e) => {
              console.info(e)      
              this.$message.error('查询报价失败，请稍后重试')
            })
       }
        
        
      },
    
     
                  
    }
  },
  created() {  
   
  },
  mounted() {
  
  },
  methods: {  
    
    checkFieldsShow(field){
          var showBool = true
          for(let j=0; j< columns.length; j++){            
              if(columns[j].title == field){
                showBool = false  
                break;
              }
          }
          return showBool
    },
    locationPos(field){
          var pos = -1
          for(let j=0; j< columns.length; j++){
              if( columns[j].title == field){
                  pos = j
                break;
              }
          }
          return pos
    },  
    
    getLoadData(){
        pickupList({pageSize: 9999})
        .then((res) => {
          if (0 == res.code) {          
            this.shipperGeoData = res.result.result                       
          }
        })
        .catch((e) => {
          console.info(e)
        })

        enumerationData({ tableName: 'sysCOrder' })
        .then((res) => {               
            if (res.code == 0) {
            this.receiverTypeData = res.result.sReceiverType
            this.regionData = res.result.regionCode                        
            this.weightTypeData = res.result.weightType
            
            }
        }).catch((e) => {
            console.info(e)
        })


        
      dispatchCityUniqueList({})
      .then((res) => {
        if (0 == res.code) {
          let result = res.result
          let dataset = []
          let dataIdx = 0
          let citySelected = []          
          for (let i = 0; i < result.length; i++) {                                     
            result[i].cityName = result[i].provinceName +"/"+ result[i].cityName            
            dataset.push(result[i])
            dataIdx++
          }          
          this.receiverGeoData = dataset
        }
      })
      .catch((e) => {
        console.info(e)
      })

    geoCascaderList({showCity:0})
      .then((res) => {
        if (0 == res.code) {          
          this.provinceData = res.result                  
        }
      })
      .catch((e) => {
        console.info(e)
      })


    
    extTextInfo({textType: 1})
      .then((res) => {
        if (0 == res.code) {
          const record = res.result
          if (typeof(record) != 'object' || record==null || record.content2  == undefined) {
            return;
          }
          this.content2 = record.content2                
        }else{
           this.$message.error(res.message)
        }
      })
     
    },  
    
    pageFormSubmit(){            
     
      //发件地址
      var shipperGeoRow = this.shipperGeoData[this.queryParam.shipperGeoCode]     
      this.queryParam.shipperProvinceCode = shipperGeoRow.provinceCode
      this.queryParam.shipperCityCode = shipperGeoRow.cityCode
      this.queryParam.shipperDistrictCode = shipperGeoRow.districtCode
              
      //收件地址         
      this.queryParam.receiverGeo = []
      let receiverGeoInfo = {}
      if(this.queryParam.receiverType == 2){
          if(this.queryParam.provinceCode == undefined){
              this.$message.error('请选择省份')
              return false
          }
          for(let h=0; h<this.queryParam.provinceCode.length; h++){
               receiverGeoInfo = {
                    provinceCode: this.queryParam.provinceCode[h]                       
                }
               this.queryParam.receiverGeo.push(receiverGeoInfo)
          }              
      }                   
      if(this.queryParam.receiverType == 3){
        
        if(this.queryParam.regionCode == undefined){
           this.$message.error('请选择大区')
           return false
         } 
         for(let t=0; t<this.queryParam.regionCode.length; t++){
            receiverGeoInfo = {
                      regionCode: this.queryParam.regionCode[t]                       
                  }
            this.queryParam.receiverGeo.push(receiverGeoInfo)
         }          
      }      
    
      if(this.queryParam.receiverType == 4){
            if(this.queryParam.receiverGeoCode == undefined){
                this.$message.error('请选择收件地址')
                return false
            } 
            for (let i = 0; i < this.queryParam.receiverGeoCode.length; i++) {
                let cityName = this.queryParam.receiverGeoCode[i];                
                 for (let i = 0; i < this.receiverGeoData.length; i++) {                   
                    if(this.receiverGeoData[i].cityName == cityName){
                        let receiverGeoRow = this.receiverGeoData[i]                        
                        receiverGeoInfo = {
                            provinceCode: receiverGeoRow.provinceCode,
                            cityCode: receiverGeoRow.cityCode,
                            districtCode: receiverGeoRow.districtCode,
                        }             
                        this.queryParam.receiverGeo.push(receiverGeoInfo)  
                        break;
                    }                                                     
                  }                                                         
            }
      }    
             
     if(this.queryParam.actualWeight == undefined){
           this.$message.error('请选择选择重量')
           return false
      }

  
      return true;     
    },    
    receiverChange($event){        
        let receiverType = $event.target.value
        this.queryParam.receiverType = receiverType
        if(receiverType == 1){
          this.provinceBool = false
          this.regionBool = false
          this.customizeBool = false
        }
        if(receiverType == 2){
          this.provinceBool = true
          this.regionBool = false
          this.customizeBool = false
        }
        if(receiverType == 3){
          this.regionBool = true
          this.provinceBool = false          
          this.customizeBool = false
        }
        if(receiverType == 4){
          this.customizeBool = true
          this.regionBool = false
          this.provinceBool = false                    
        }
    },

    downloadExcel(){

      var checkQueryParamBool = this.pageFormSubmit() 
      if(checkQueryParamBool == false){

        return false
      } 
                                         
      let that = this          
      return sectionPrice(Object.assign({"pageNo":1,"pageSize":1000}, this.queryParam))
        .then((res) => {
          if (0 == res.code) {
            let resultData = res.result.result                                                    
           this.pageDataset  = resultData             
           this.downLoadData()   
          } else {
            this.$notification.error({
              message: '加载失败',
              description: res.message,
            })                           
          }              
        })
        .catch((e) => {
          console.info(e)      
          that.$message.error('查询报价失败，请稍后重试')
        })  
     
    },

    downLoadData(){
     if (this.downloadExcelState > 0 && pageDataset.length == 0) {
        return;
      }

      var excelConfig = {
              "shipperProvinceName": "始发省份",
              "shipperCityName": "始发城市",
              "receiverProvinceName": "目的省份",
              "receiverCityName": "目的城市",
              "firstWeight": "首重KG",
              "lowestPrice": "最低收费（元）",
              "pickupFirstWeightPrice": "提货费起价（元）",
              "pickupContinuedWeightPrice": "提货费单价（元）",
              "pickupUpperLimitPrice": "提货费上限（元）",
              "dispatchUpperLimitPrice": "派费上限（元）",
              "range2FirstWeightPriceTransportPrice": "100KG干线单价（元）",  
              "range2FirstWeightPriceDispatchFirstWeightPrice": "100KG派送最低收费（元）",
              "range2FirstWeightPriceDispatchContinuedWeightPrice": "100KG派送续重单价（元）",
              "range3FirstWeightPriceTransportPrice": "300KG干线单价（元）",
              "range3FirstWeightPriceDispatchFirstWeightPrice": "300KG派送最低收费（元）",
              "range3FirstWeightPriceDispatchContinuedWeightPrice": "300KG派送续重单价（元）",
              "range4FirstWeightPriceTransportPrice": "500KG干线单价（元）",
              "range4FirstWeightPriceDispatchFirstWeightPrice": "500KG派送最低收费（元）",
              "range4FirstWeightPriceDispatchContinuedWeightPrice": "500KG派送续重单价（元）",
              "range5FirstWeightPriceTransportPrice": "1200KG干线单价（元）",
              "range5FirstWeightPriceDispatchFirstWeightPrice": "1200KG派送最低收费（元）",
              "range5FirstWeightPriceDispatchContinuedWeightPrice": "1200KG派送续重单价（元）",   
            };

      if(this.queryParam.actualWeight.indexOf("100") == -1){
        delete excelConfig.range2FirstWeightPriceTransportPrice
        delete excelConfig.range2FirstWeightPriceDispatchFirstWeightPrice
        delete excelConfig.range2FirstWeightPriceDispatchContinuedWeightPrice
      }
      if(this.queryParam.actualWeight.indexOf("300") == -1){
        delete excelConfig.range3FirstWeightPriceTransportPrice
        delete excelConfig.range3FirstWeightPriceDispatchFirstWeightPrice
        delete excelConfig.range3FirstWeightPriceDispatchContinuedWeightPrice
      }
      if(this.queryParam.actualWeight.indexOf("500") == -1){
        delete excelConfig.range4FirstWeightPriceTransportPrice
        delete excelConfig.range4FirstWeightPriceDispatchFirstWeightPrice
        delete excelConfig.range4FirstWeightPriceDispatchContinuedWeightPrice
      }
      if(this.queryParam.actualWeight.indexOf("1200") == -1){
        delete excelConfig.range5FirstWeightPriceTransportPrice
        delete excelConfig.range5FirstWeightPriceDispatchFirstWeightPrice
        delete excelConfig.range5FirstWeightPriceDispatchContinuedWeightPrice
      }
     
      this.downloadExcelState = 1;
      this.downloadExcelDataset = [];
      let downloadExcelTitle = [];  
      for (let key in excelConfig) {
        downloadExcelTitle.push(excelConfig[key]);
      }
         
      this.downloadExcelDataset.push(downloadExcelTitle);
      for (let i = 0; i < this.pageDataset.length; i++) {
        const dataset = [
          this.pageDataset[i].shipperProvinceName,
          this.pageDataset[i].shipperCityName,       
          this.pageDataset[i].receiverProvinceName,
          this.pageDataset[i].receiverCityName,
          this.pageDataset[i].firstWeight,  
          this.pageDataset[i].lowestPrice,
          this.pageDataset[i].pickupFirstWeightPrice,
          this.pageDataset[i].pickupContinuedWeightPrice,
          this.pageDataset[i].pickupUpperLimitPrice,            
          this.pageDataset[i].dispatchUpperLimitPrice,                
        ];

        if(this.queryParam.actualWeight.indexOf("100") > -1){
          dataset.push(this.pageDataset[i].range2FirstWeightPrice.transportPrice)
          dataset.push(this.pageDataset[i].range2FirstWeightPrice.dispatchFirstWeightPrice)
          dataset.push(this.pageDataset[i].range2FirstWeightPrice.dispatchContinuedWeightPrice)
        }
        if(this.queryParam.actualWeight.indexOf("300") > -1){
          dataset.push(this.pageDataset[i].range3FirstWeightPrice.transportPrice)
          dataset.push(this.pageDataset[i].range3FirstWeightPrice.dispatchFirstWeightPrice)
          dataset.push(this.pageDataset[i].range3FirstWeightPrice.dispatchContinuedWeightPrice)
        }
        if(this.queryParam.actualWeight.indexOf("500") > -1){
          dataset.push(this.pageDataset[i].range4FirstWeightPrice.transportPrice)
          dataset.push(this.pageDataset[i].range4FirstWeightPrice.dispatchFirstWeightPrice)
          dataset.push(this.pageDataset[i].range4FirstWeightPrice.dispatchContinuedWeightPrice)
        }
        if(this.queryParam.actualWeight.indexOf("1200") > -1){
          dataset.push(this.pageDataset[i].range5FirstWeightPrice.transportPrice)
          dataset.push(this.pageDataset[i].range5FirstWeightPrice.dispatchFirstWeightPrice)
          dataset.push(this.pageDataset[i].range5FirstWeightPrice.dispatchContinuedWeightPrice)
        }
        this.downloadExcelDataset.push(dataset);
      }

      let content2 = this.content2
      let content2Arr = content2.split("\n")     
      this.downloadExcelDataset.push(['注：']);
      for(let i=0;i < content2Arr.length;i++){
          this.downloadExcelDataset.push([content2Arr[i]]);
      }            
      this.downloadDataset();
    },
    downloadDataset() {
      let that = this;
      let sheet = XLSX.utils.aoa_to_sheet(that.downloadExcelDataset);
      let book = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(book, sheet, '报价表');
      XLSX.writeFile(book, '报价表.xls');
      that.downloadExcelState = 0;
    }
  },
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100%;
}
.ant-form{
  /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px;
    }
    .percentage{
      border-radius:0 5px 5px 0;
      border-left: 0;
    }
  }
}
/deep/ .ant-table-tbody{
  background-color: #fff;
}
.ant-form{
  background-color: #fff;
  padding: 24px 24px 0 24px;
  border-radius: 5px;
}
.table-page-search-wrapper{
  margin-bottom: 20px;
}
/deep/ .ant-select-selection--single{
  height: 35px;
  line-height: 35px;
  .ant-select-selection__rendered{
    line-height: 35px;
  }
}
/deep/ .ant-form-item-control{
  height: 35px !important;
  line-height: 35px !important;
  .ant-cascader-input{
    height: 35px !important;
  }
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
</style>